import { SortDirection } from '@angular/material/sort';

export interface Sort {
  [field: string]: SortDirection;
}

export interface Page<T> {
  page: number;
  pageSize: number;
  totalItems?: number;
  sort?: Sort;
  content?: T[];
}

export const DEFAULT_PAGE: Page<never> = {
  page: 0,
  pageSize: 10
};

export function addPageParams(url: string, page: Page<unknown>): string {
  let result = url.includes('?') ? `${url}&pageSize=${page.pageSize || 10}` : `${url}?pageSize=${page.pageSize || 10}`;

  result = `${result}&page=${page.page || 0}`;

  if (page.sort) {
    Object.keys(page.sort)
      .forEach(key => {
        result = `${result}&sortBy=${key}&sortDir=${page.sort ? page.sort[key] : 'asc'}`;
      });
  }

  return result;
}
