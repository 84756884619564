import { NotificationHandler } from '@app/common/helper';
import JSZip from 'jszip';
import saveAs from 'file-saver';

enum MimeType {
  text = 'text/plain',
  csv = 'text/csv',
  jpeg = 'image/jpeg',
  jpg = 'image/jpg',
}

export class DownloadUtil {
  public static async downloadRemoteFile(url: string, filename: string) {
    const response = await fetch(url);
    const objectURL = URL.createObjectURL(await response.blob());

    const link = window.document.createElement('a');
    link.href = objectURL;
    link.download = filename;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  public static downloadTextAsFile(text: string, filename: string, extension: 'txt' | 'csv' | 'jpg' = 'txt') {
    let type = MimeType.text;
    if (extension === 'csv') {
      type = MimeType.csv;
    }

    const blob = new Blob([text], { type });
    const objectURL = window.URL.createObjectURL(blob);

    const link = window.document.createElement('a');
    link.href = objectURL;
    link.download = `${filename}.${extension || 'txt'}`;
    link.click();

    window.URL.revokeObjectURL(objectURL);
  }

  public static downloadDataUrlAsFile(dataURL: string, filename: string, extension: 'txt' | 'csv' | 'jpg' = 'txt') {

    const link = window.document.createElement('a');
    link.href = dataURL;
    link.download = `${filename}.${extension || 'txt'}`;
    link.click();

    window.URL.revokeObjectURL(dataURL);
  }

  static async downloadFiles(files: File[], archiveName: string) {
    const zip = new JSZip();

    files.forEach(file => zip.file(file.name, file));

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${archiveName || 'archive'}.zip`);
    } catch (error) {
      console.error('Failed to generate ZIP file:', error);
      NotificationHandler.handleError(`Failed to generate ZIP file: ${(error as Error).message}`);
    }
  }

  static async fetchFromUrl(url: string) {
    try {
      return (await fetch(url)).blob();
    } catch (error) {
      throw new Error((error as Error).message || `Failed to download the file by the URL: ${url}`);
    }
  }

  static async downloadFromUrl(url: string, fileName?: string) {
    try {
      const blob = await DownloadUtil.fetchFromUrl(url);
      const name = fileName || url.substring(url.lastIndexOf('/') + 1);

      saveAs(blob, name);
    } catch (error) {
      NotificationHandler.handleError((error as Error).message || `Failed to download the file by the URL: ${url}`);
    }
  }

  static async downloadFromUrls(urls: string[], archiveName: string) {
    const zip = new JSZip();

    for (const url of urls) {
      const blob = await DownloadUtil.fetchFromUrl(url);
      const filename = url.substring(url.lastIndexOf('/') + 1);
      zip.file(filename, blob);
    }

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${archiveName || 'archive'}.zip`);
    } catch (error) {
      console.error('Failed to generate ZIP file:', error);
      NotificationHandler.handleError(`Failed to generate ZIP file: ${(error as Error).message}`);
    }
  }
}

