import { DataUtil } from '@app/helper';
import { ProductType } from './product-type.model';
import { Size } from '@entities/order';

export enum ProductSize {
  ExtraSmall = 'Extra Small',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'Extra Large',
  Dynamic = 'Dynamic',
}

export interface ProductPanel extends Size {
  title: string;
  ratio: number;
}

export const MIN_IMAGE_SIZE = 500;

export enum LayoutType {
  BowHorizontal = 'Bow Horizontal',
  CoreHorizontal = 'Core Horizontal',
  CoreVertical = 'Core Vertical',
  Hexagon = 'Hexagon',
  Horizontal = 'Horizontal',
  HorizontalSuperWide = 'Horizontal Super Wide',
  Mess = 'Mess',
  Mix = 'Mix',
  MixSet = 'Mix Set',
  Panoramic = 'Panoramic',
  PanoramicVertical = 'Panoramic Vertical',
  Square = 'Square',
  SquareVertical = 'Square Vertical',
  Star = 'Star',
  Triangle = 'Triangle',
  Vertical = 'Vertical',
  VerticalSuperWide = 'Vertical Super Wide',
  VerticalWide = 'Vertical Wide',
  Wine = 'Wine',
}

export class Product {
  private id = DataUtil.generateId();
  public name: string;
  public code: string;
  public type: ProductType;
  public numberOfParts: number;
  public size: ProductSize;
  public totalSize?: ProductPanel;
  public dynamicSize: boolean;
  public images: ProductPanel[];
  public panels: ProductPanel[];
  public layoutType?: LayoutType;
  public price: number;
  public bleedSize?: number;

  constructor(code: string, name: string, type: ProductType, numberOfParts: number,
              size: ProductSize, panelSizes: ProductPanel[], imageSizes: ProductPanel[], price: number) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.numberOfParts = numberOfParts;
    this.size = size;
    this.dynamicSize = size == ProductSize.Dynamic;
    this.panels = panelSizes;
    this.images = imageSizes;
    this.price = price;
  }

  public getKey(modifier?: string): string {
    return modifier ? `${this.id}-${modifier}` : this.id;
  }

  public getTitle(includeCode = false): string {
    let size = '';
    if (this.dynamicSize) {
      size = ` (${this.panels[0].title})`
    }
    return `${includeCode ? `${this.code}: ` : ''}${this.name}${size}`;
  }
}
