import { ProductType } from '@entities/product';

export interface AssetUpload {
  key: string;
  uploadUrl: string;
  previewUrl: string;
  url: string;

  metadata?: AssetMetadata;
}

export interface AssetMetadata {
  width: number;
  height: number;
  size: number;
  thumb?: string;
}

export interface AssetUploadRequest {
  type?: AssetType;
  fileName: string;
  uploadKey?: string;
}

export interface Asset {
  id: string;
  type: AssetType;
  name: string;
  url: string;
  previewUrl: string;
  width: number;
  height: number;
  size: number;
  tags?: string[];
}

export interface AssetInteraction {
  productType: ProductType;
  productCode: string;
  panelIndex: number;
}

export type RegisterAsset =  AssetInteraction & Omit<Asset, 'id'>;

export enum AssetType {
  catalogItem = 'catalog-item',
  productOption = 'product-option',
  productType = 'product-type',
  tmpSource = 'tmp-source',
}

enum ContentType {
  jpg = 'image/jpg',
  png = 'image/png',
  gif = 'image/gif',
  svg = 'image/svg+xml',
  csv = 'text/csv',
  binary = 'application/octet-stream',
}

export const parseContentType = (extension: string): ContentType => {
  switch (extension.toLowerCase()) {
    case 'jpeg':
    case 'jpg':
      return ContentType.jpg;
    case 'png':
      return ContentType.png;
    case 'gif':
      return ContentType.gif;
    case 'svg':
      return ContentType.svg;
    case 'csv':
      return ContentType.csv;
    default:
      return ContentType.binary;
  }
};

export const getFileMetadata = (file: File): { extension: string; mime: string } => {
  const extension = file.name.split('.').pop() || '';

  return {
    extension,
    mime: parseContentType(extension),
  };
};
