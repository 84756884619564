import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorDetails {
  error?: {
    details?: string;
    message?: string;
  }
  message?: string;
}

export class ErrorUtil {
  static extractErrorMessage(error: string | ErrorDetails | HttpErrorResponse | Error): string {
    let errorMessage: string | undefined;

    try {

      if (typeof error === 'string') {
        errorMessage = error;
      }

      else if ((error as HttpErrorResponse)?.error?.error?.message) {
        errorMessage = (error as HttpErrorResponse)?.error?.error?.message;
      }

      else if (
        (error as ErrorDetails)?.error?.details ||
        (error as ErrorDetails)?.error?.message ||
        (error as ErrorDetails)?.message
      ) {
        errorMessage = (error as ErrorDetails)?.error?.details || (error as ErrorDetails)?.error?.message;
      }

      else if ((error as Error).message) {
        errorMessage = (error as Error).message;
      }

      return errorMessage ?? 'Unknow error';
    } catch (err) {
      console.error(err);
      return 'Unknown error';
    }
  }
}