import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment';
import { API_CONTEXT, ApiContext } from '@app/helper';
import { ShippingRateQuery, ShippingRate } from '../model';

@Injectable({ providedIn: 'root' })
export class RVPIntegrationService {
  private http$ = inject(HttpClient);

  public getShippingRate(data: ShippingRateQuery): Observable<ShippingRate> {
    return this.http$
      .post<ShippingRate>(`${environment.api.integration}/orders/shipping/rate`, data, {
        context: new HttpContext().set(API_CONTEXT, ApiContext.integration),
      })
  }
}
