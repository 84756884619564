export class ArrayUtil {

  public static getMax(numbers: number[]): number {
    return Math.max(...numbers);
  }

  public static getSum(numbers: number[]): number {
    return numbers.reduce((total, num) => total + num, 0);
  }

  /**
   * Split the original array to multiple arrays, each with at most {{ size }} length.
   * Original array is not affected
   *
   * @param array - original array
   * @param size - size of the chunk
   */
  public static arrayChunk<T>(array: T[], size: number): T[][] {
    const arrays = Array.from(
      new Array(Math.ceil(array.length / size)),
      (_, i) => array.slice(i * size, i * size + size));

    return arrays || [];
  }

}
