import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RetryableImageComponent } from '../retryable-image/retryable-image.component';

@Component({
  selector: 'panel-viewer',
  standalone: true,
  templateUrl: './panel-viewer.component.html',
  styleUrl: './panel-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RetryableImageComponent],
})
export class PanelViewerComponent {
  readonly images = input<string[]>();
}
