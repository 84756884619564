import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { ProductType, ProductTypeDetails } from '../model/product-type.model';
import { Product } from '../model/product.model';
import { ProductDto } from '../model/dto.model';
import {
  ProductOptions, ProductOption, OptionType, ProductOptionsGroup, OptionSubType, ProductOptionsData,
} from '../model/product-option.model';

import { Cache } from '@app/common/decorators/cache.decorator';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private http = inject(HttpClient);

  private static readonly CACHE_TTL = 1000 * 60;
  private static readonly PRODUCT_TYPE = 'PRODUCT_TYPE';
  private static readonly OPTION_TYPE = 'OPTION_TYPE';

  static readonly BASE_URL = '/products';
  private static readonly TYPES_BASE_URL = `${ProductService.BASE_URL}/types`;
  private static readonly AVAILABLE_TYPES_URL = `${ProductService.TYPES_BASE_URL}/available`;
  private static readonly SYSTEM_TYPES_BASE_URL = `${ProductService.TYPES_BASE_URL}/system`;
  private static readonly OPTIONS_BASE_URL = `${ProductService.BASE_URL}/options`;
  private static readonly OPTIONS_TYPE_BASE_URL = `${ProductService.TYPES_BASE_URL}/${ProductService.PRODUCT_TYPE}/options/${ProductService.OPTION_TYPE}`;

  @Cache({ ttl: ProductService.CACHE_TTL })
  public getProductTypes(): Observable<ProductTypeDetails[]> {
    return this.http.get<ProductTypeDetails[]>(`${environment.api.host}${ProductService.TYPES_BASE_URL}`);
  }

  public getSystemProductTypes(): Observable<ProductTypeDetails[]> {
    return this.http.get<ProductTypeDetails[]>(`${environment.api.host}${ProductService.SYSTEM_TYPES_BASE_URL}`);
  }

  public getAvailableProductTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(`${environment.api.host}${ProductService.AVAILABLE_TYPES_URL}`);
  }

  public saveAvailableProductTypes(types: ProductType[]): Observable<unknown> {
    return this.http.post<unknown>(`${environment.api.host}${ProductService.AVAILABLE_TYPES_URL}`, types);
  }

  @Cache({ ttl: ProductService.CACHE_TTL })
  public getProductOptions(): Observable<ProductOptions[]> {
    return this.http.get<ProductOption[]>(`${environment.api.integration}${ProductService.OPTIONS_BASE_URL}`)
      .pipe(
        map(options => {
          const availableProductTypes = [...new Set(options.map(opt => opt.productType))];

          return availableProductTypes.map(productType => {
            const types = [OptionType.frame, OptionType.finish, OptionType.hanging, OptionType.cover, OptionType.other, OptionType.print];

            const config = {
              productType,
            };

            return types.reduce((optionsMap, optionType) => {
              const groups = this.buildOptionsGroup(productType, optionType, options);
              return groups.length ? { ...optionsMap, [optionType]: groups } : optionsMap;
            }, config);
          });
        }),
      );
  }

  private buildOptionsGroup(productType: string, optionType: OptionType, options: ProductOption[]): ProductOptionsGroup[] {
    const optionsSubTypes = [...new Set(options.filter(opt => opt.productType === productType && opt.type === optionType).map(opt => opt.subType))];

    return optionsSubTypes.reduce((groups, subType) => {
      const subTypeOptions = options.filter(opt => opt.productType === productType && opt.type === optionType && opt.subType === subType);
      return subTypeOptions.length ? [
        ...groups,
        {
          type: optionType,
          subType,
          options: subTypeOptions,
          name: subTypeOptions[0].name || ''
        }
      ] : groups;
    }, [] as ProductOptionsGroup[]);
  }

  public saveProductOptions(productType: ProductType,
    optionType: OptionType,
    optionSubType: OptionSubType | undefined,
    data: ProductOptionsData): Observable<ProductOptionsData> {
    let url = ProductService.OPTIONS_TYPE_BASE_URL
      .replace(ProductService.PRODUCT_TYPE, encodeURIComponent(productType))
      .replace(ProductService.OPTION_TYPE, encodeURIComponent(optionType));
    if (optionSubType) url += `?subType=${optionSubType}`;

    return this.http.post<ProductOptionsData>(`${environment.api.host}${url}`, data);
  }

  public deleteProductOptions(productType: ProductType, optionType: OptionType, optionSubType?: OptionSubType): Observable<unknown> {
    let url = ProductService.OPTIONS_TYPE_BASE_URL
      .replace(ProductService.PRODUCT_TYPE, encodeURIComponent(productType))
      .replace(ProductService.OPTION_TYPE, encodeURIComponent(optionType));
    if (optionSubType) url += `?subType=${optionSubType}`;

    return this.http.delete(`${environment.api.host}${url}`);
  }

  @Cache({ ttl: ProductService.CACHE_TTL })
  public getProducts(types?: ProductType[]): Observable<Product[]> {
    return this.http.get<ProductDto[]>(`${environment.api.integration}${ProductService.BASE_URL}`)
      .pipe(
        map(products => products
          .filter(product => product.fixedImageCount)              // filter out dynamic image count
          // .filter(product => product.numberOfParts == 1)           // filter out multi-panels
          .filter(product => types?.length ? types.includes(product.productType) : true)  // filter out unsupported types
          .map(dto => {
            const product = new Product(
              dto.productCode,
              dto.name,
              dto.productType,
              dto.numberOfParts,
              dto.size,
              dto.panelSizes,
              dto.imageSizes,
              dto.price
            );

            product.layoutType = dto.layoutType;
            product.bleedSize = dto.bleedSize;
            product.totalSize = dto.totalSize || product.panels[0];

            return product;
          })
        )
      );
  }

}
