import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import {
  Asset,
  AssetInteraction,
  AssetMetadata,
  AssetType,
  AssetUpload,
  AssetUploadRequest,
  getFileMetadata
} from '@app/components/common/model/asset';
import { map, switchMap } from 'rxjs/operators';
import { addPageParams, Page } from '@common/model/page';
import { ObjectUtil } from '@app/helper/object.util';
import { ProductType } from '@entities/product';

@Injectable({
  providedIn: 'root'
})
export class ProductImageService {

  private static readonly ASSET_ID = 'ASSET_ID';

  private static readonly BASE_URL = '/products';
  private static readonly IMAGE_BASE_URL = `${ProductImageService.BASE_URL}/images`;
  private static readonly CATALOG_BASE_URL = `${ProductImageService.IMAGE_BASE_URL}/register`;
  private static readonly ASSET_DETAILS_URL = `${ProductImageService.IMAGE_BASE_URL}/${ProductImageService.ASSET_ID}`;

  constructor(private http: HttpClient) {
  }

  private registerImageUpload(data: AssetUploadRequest): Observable<AssetUpload> {
    const host = data.type == AssetType.tmpSource || data.type == AssetType.catalogItem
      ? environment.api.integration
      : environment.api.host;

    return this.http.post<AssetUpload>(`${host}${ProductImageService.IMAGE_BASE_URL}`, data);
  }

  private uploadAsset<T extends { url: string }>(uploadUrl: string, file: File): Observable<T> {
    const headers = new HttpHeaders({ 'Content-Type': getFileMetadata(file).mime });
    return this.http.put<T>(uploadUrl, file, { headers });
  }

  public uploadImage(file: File, type: AssetType): Observable<AssetUpload> {
    return this.registerImageUpload({ fileName: file.name, type }).pipe(
      switchMap((assetData: AssetUpload) => {
        return this.uploadAsset(assetData.uploadUrl, file).pipe(
          map(() => assetData)
        );
      }),
    );
  }

  public getImageMetadata(file: File, uploadKey: string): Observable<AssetMetadata> {
    const data: AssetUploadRequest = {
      fileName: file.name,
      uploadKey
    };
    return this.http.post<AssetUpload>(`${environment.api.integration}${ProductImageService.IMAGE_BASE_URL}`, data)
      .pipe(
        map<AssetUpload, AssetMetadata>(asset => asset.metadata || { size: file.size, width: 0, height: 0 })
      );
  }

  public registerCatalogItem(data: Omit<Asset, 'id'>): Observable<Asset> {
    return this.http.post<Asset>(`${environment.api.host}${ProductImageService.CATALOG_BASE_URL}`, data);
  }

  public updateCatalogItem(assetId: string, data: Omit<Asset, 'id'>): Observable<Asset> {
    return this.http.put<Asset>(`${environment.api.host}${ProductImageService.IMAGE_BASE_URL}/${assetId}`, data);
  }

  public addImageInteraction(assetId: string, data: AssetInteraction): Observable<void> {
    const url = ProductImageService.ASSET_DETAILS_URL.replace(ProductImageService.ASSET_ID, assetId);
    return this.http.post<void>(`${environment.api.host}${url}/interactions`, data);
  }

  public getCatalogItems(page: Page<unknown>,
                         data?: { search?: string; },
                         productInfo?: { type?: ProductType; code?: string; panel?: number }): Observable<Page<Asset>> {
    const url = addPageParams(ProductImageService.IMAGE_BASE_URL, page);

    let params = new HttpParams();
    if (data?.search) {
      params = params.set('query', data.search);
    }

    let headers = new HttpHeaders();
    if (productInfo?.type || productInfo?.code || productInfo?.panel) {
      headers = headers.set('x-sort', ObjectUtil.toHeader(productInfo));
    }

    return this.http.get<Page<Asset>>(`${environment.api.host}${url}`, { params, headers });
  }

  public getUserTags(search?: string): Observable<string[]> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<string[]>(`${environment.api.host}${ProductImageService.IMAGE_BASE_URL}/tags`, { params });
  }

  public addUserTags(assetId: string, tags: string[]): Observable<void> {
    const url = ProductImageService.ASSET_DETAILS_URL.replace(ProductImageService.ASSET_ID, assetId);
    return this.http.post<void>(`${environment.api.host}${url}/tags`, { tags });
  }

  public deleteCatalogItem(assetId: string): Observable<unknown> {
    const url = ProductImageService.ASSET_DETAILS_URL.replace(ProductImageService.ASSET_ID, assetId);
    return this.http.delete(`${environment.api.host}${url}`);
  }
}
