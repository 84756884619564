import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import Auth, { CognitoUser } from '@aws-amplify/auth';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError, } from 'rxjs';
import { catchError, map, take, tap, } from 'rxjs/operators';
import { environment } from '@environment';
import { ClientDetails } from '@app/components/common/model/client';
import { ClientService } from '@app/components/common';
import { LoadingState } from '../model/shared';
import { Session } from '../model/auth/session';
import { Role } from '../model/auth/role';
import { User } from '../model/auth/user';
import { NotificationHandler } from '../helper';

interface UserAttributes {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  preferred_username?: string;
}

interface UserDetails extends Pick<ClientDetails, 'enterprise'> {
  email: string;
  id: string;
  name: string;
  roles: Role[];
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private router = inject(Router);
  private http = inject(HttpClient);
  private dialogRef = inject(MatDialog);
  private clientService = inject(ClientService);

  private readonly userDetails$ = new BehaviorSubject<UserDetails | null>(null);
  private readonly userData$ = new BehaviorSubject<User | null>(null);
  readonly user$ = this.userData$.asObservable();
  private readonly userDataLoadingState$ = new BehaviorSubject<LoadingState>(LoadingState.INIT);
  readonly userLoadingStage$ = this.userDataLoadingState$.asObservable();

  initAuth(): void {
    Auth.configure({
      region: environment.auth.region,
      userPoolId: environment.auth.pool_id,
      userPoolWebClientId: environment.auth.client_id,
      mandatorySignIn: true,
    });

    this.getUser().pipe(take(1)).subscribe();
  }

  private static getAttributes(cognitoUser: CognitoUser): UserAttributes {
    return ((cognitoUser as unknown) as { attributes: UserAttributes }).attributes;
  }

  getUser(bypassCache = false): Observable<User> {
    return combineLatest([
      from(Auth.currentAuthenticatedUser({ bypassCache }) as Promise<CognitoUser>),
      this.getUserDetails(bypassCache),
      this.clientService.getClientConfig(),
    ]).pipe(
      map(([cognitoUser, authData, clientConfig]) => {
        const user = new User(cognitoUser.getUsername());

        const attributes = AuthService.getAttributes(cognitoUser);

        user.email = attributes.email;
        user.firstName = attributes.given_name;
        user.lastName = attributes.family_name;
        user.username = attributes.preferred_username;
        user.enterprise = Boolean(authData.enterprise);

        user.clientId = clientConfig.id;

        return user;
      }),
      tap(user => this.userData$.next(user)),
      catchError(err => {
        if (this.userData$.getValue()) {
          this.userData$.next(null)
          this.router.navigate(['/auth/sign-in']);
        }
        this.userDataLoadingState$.next(LoadingState.ERROR);
        return throwError(() => err)
      })
    );
  }

  getUserClientId(): string {
    const clientId = this.userData$.getValue()?.clientId;
    if (!clientId) {
      NotificationHandler.handleError('User is not associated with a client');
      throw new Error('User is not associated with a client');
    }

    return clientId;
  }

  private getUserDetails(bypassCache = false): Observable<UserDetails> {
    if (bypassCache === false && this.userDetails$.getValue()) {
      return this.userDetails$.asObservable() as Observable<UserDetails>;
    }

    this.userDataLoadingState$.next(LoadingState.LOADING);
    return this.http.get<UserDetails>(`${environment.api.host}/auth`).pipe(
      tap(data => {
        this.userDetails$.next(data);
        this.userDataLoadingState$.next(LoadingState.LOADED);
      }),
    )
  }

  getSession(): Observable<Session> {
    return from(Auth.currentSession())
      .pipe(
        map(cognitoSession => {
          const groups = cognitoSession.getAccessToken().payload['cognito:groups'] as string[] || [];
          const roles = groups.map((group: string) => group as Role);
          const apiKey = cognitoSession.getIdToken().payload['custom:api_key'] as string || '';

          return new Session(roles, cognitoSession.getIdToken().getJwtToken(), apiKey);
        }),
        catchError(() => {
          return of(new Session([]));
        })
      );
  }

  signOut(): Observable<void> {
    return from(Auth.signOut())
      .pipe(
        tap(() => {
          this.dialogRef.closeAll();
        })
      );
  }

}
