/* eslint-disable @typescript-eslint/no-explicit-any */
import { StringUtil } from '@app/helper';
import { Observable, shareReplay, take, timer } from 'rxjs';

export interface CacheOptions {
  ttl: number; // Time-to-live in milliseconds
}

export function Cache(options: CacheOptions) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalFunction = descriptor.value;


    try {
      descriptor.value = function (this: Record<string, any>, ...args: any[]): Observable<any> {
        const cacheKey = StringUtil.getSimpleHash(`${propertyKey}_cache_${JSON.stringify(args)}`);

        if (!this[cacheKey]) {
          // Create a single observable that will auto-expire after TTL
          const cached$ = originalFunction
            .apply(this, args)
            .pipe(
              shareReplay({ bufferSize: 1, refCount: false, windowTime: options.ttl }),
            );

          // Assign the cached observable to the cache key
          this[cacheKey] = cached$;

          // Invalidate the cache after TTL
          timer(options.ttl)
            .pipe(take(1))
            .subscribe(() => this[cacheKey] = null);
        }

        return this[cacheKey];
      };
    } catch (err) {
      console.warn('Can not cache request')
    }

    return descriptor;
  };
}
