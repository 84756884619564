import { HttpClient } from '@angular/common/http';
import { ProductPrice, ProductPriceData } from '@app/components/common/model/product-price';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OptionType, Product, SelectedOption } from '@entities/product';

export type OptionPriceHandler = (product: Product, option: SelectedOption) => number;
export type OptionsPriceHandler = (product: Product, options: SelectedOption[]) => number;

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  private static readonly PRODUCT_CODE = 'PRODUCT_CODE';

  private static readonly PRICE_URL = `/products/${PricingService.PRODUCT_CODE}/price`;

  constructor(private http: HttpClient) {
  }

  public static optionPrice(product: Product, option: SelectedOption): number {
    switch (option.type) {
      case OptionType.frame:
        return product.panels
          .map(panel => (panel.width + panel.height) * 2)
          .map(length => option.option.price * length)
          .reduce((a, b) => a + b, 0);
      case OptionType.hanging:
      case OptionType.cover:
      case OptionType.print:
      case OptionType.finish:
      case OptionType.other:
        return option.option.price;
    }
  }

  public static optionsPrice(product: Product, options: SelectedOption[]): number {
    return options
      .map(option => PricingService.optionPrice(product, option))
      .reduce((a, b) => a + b, 0);
  }

  public getProductPrice(product: Product, width = 0, height = 0): Observable<ProductPrice> {
    const url = PricingService.PRICE_URL.replace(PricingService.PRODUCT_CODE, product.code);

    const data: ProductPriceData = {
      ...product.dynamicSize && {
        sizes: [{width, height}]
      }
    };
    return this.http.post<ProductPrice>(`${environment.api.integration}${url}`, data);
  }
}
