<div class="previewer">
  <div class="preview">
    @for (image of images(); track image; let i = $index) {
    <retryable-image mat-card-image
                     [src]="image"
                     alt="panel image"
                     class="panel-image"></retryable-image>
    }
  </div>

</div>
