import { v4 as uuid } from 'uuid';

export class DataUtil {

  public static generateId(): string {
    return uuid();
  }

  public static generateTimeBasedId(): string {
    return (Date.now() / 1000).toFixed(0) + (Math.random().toString(36).substring(2, 6)).toUpperCase();
  }

  public static getTimestamp(): string {
    return new Date().getTime().toString();
  }

  public static capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  public static capitalizeFirstLettersForSentence(value = '', clear = false): string {
    if (clear) value = value.replaceAll('_', ' ').replaceAll('-', ' ');
    return value.split(' ').map((c: string) => DataUtil.capitalizeFirstLetter(c)).join(' ');
  }

  public static getFileExtension(url = ''): string {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim() || '';
  }

  public static getFileName(url: string): string | null {
    try {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
      return filename || '';
    } catch (error) {
      return '';
    }
  }

  public static dataUrlToBlob(dataURL: string) {
    const [header, base64] = dataURL.split(',');
    const mimeMatch = header.match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : 'image/jpeg';
    const binary = atob(base64);
    const array = new Uint8Array(binary.length);

    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }

    return new Blob([array], { type: mime });
  }

  public static dataUrlToFile(dataUrl: string, fileName: string) {
    const blob = DataUtil.dataUrlToBlob(dataUrl);
    return new File([blob], fileName, { type: blob.type });
  }


  public static roundToDecimal(value: number, places = 2) {
    return Math.round((value + Number.EPSILON) * 10 ** places) / 10 ** places;
  }

  public static ceilToDecimal(value: number, places = 2) {
    return Math.ceil((value + Number.EPSILON) * 10 ** places) / 10 ** places;
  }
}
