import { ShippingCarrier, ShippingCode, ShippingInfo } from '@app/components/common/model/shipping';
import { OptionsPriceHandler } from '@app/components/common/service/pricing.service';
import { OrderItem, Size } from '@entities/order';
import { SelectedOption } from '@entities/product';

export interface OrderShipmentBase {
  carrier: ShippingCarrier;
  shippingCode?: ShippingCode;
  shippedAt?: Date;
}

export interface OrderBase extends OrderShipmentBase {
  id: string;
  clientOrderId: string;

  status: OrderStatus;

  rush?: boolean;
  factoryAlert?: boolean;
  factoryNote?: string;

  orderedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrderShipment extends OrderShipmentBase {
  shipToCustomer: ShippingInfo;
  shipByDate?: Date;
  trackingNumber?: string;
  trackingUrl?: string;
  shippingLabelUrl?: string;
}

export interface Order extends OrderBase, OrderShipment {
  items: OrderItem[];
  rushType?: RushType;
  rushValue?: RushValue;
  flyers?: OrderFlyerDto[];
  declaredValue?: number;
  currency?: string;
  notes?: string;
  payment?: OrderPayment;
}

export interface OrderFilter extends Partial<Pick<OrderBase, 'clientOrderId' | 'status'>> {
  search?: string;
}

export interface BulkOrderSubmissionSuccess {
  success: true;
  order: Order;
}

export interface BulkOrderSubmissionFailed {
  success: false;
  message: string;
}

export interface BulkOrderSubmissionResult extends Pick<Order, 'payment'> {
  orders: (BulkOrderSubmissionSuccess | BulkOrderSubmissionFailed)[];
}

export type OrderPaymentSessionStatus = 'complete' | 'expired' | 'open';
export type OrderPaymentStatus = 'paid' | 'unpaid' | 'no_payment_required';

export interface OrderPayment {
  sessionUrl: string;
  sessionStatus: OrderPaymentSessionStatus;
  paymentStatus: OrderPaymentStatus;
  amountPaid: number;
  currency: string;
}

export interface OrderExtras {
  clientOrderId: string;
  notes?: string;
}

export type ProductQuantities = {
  [key: string]: number;
}

export interface PriceInfo {
  product: number;

  handler: OptionsPriceHandler;
  options: SelectedOption[];
}

export type ProductPricing = {
  [key: string]: PriceInfo;
}

export type ProductImages = {
  [key: string]: string[];
}

export interface OrderSubmit extends Omit<Order, 'id' | 'status' | 'createdAt' | 'updatedAt' | 'items'> {
  items: Omit<OrderItem, 'id'>[];
}

export type OrderItemInternal = Omit<OrderItem, 'id' | 'options'> & { options: SelectedOption[]; };

export enum OrderStatus {
  action_needed = 'action_needed',
  awaiting_payment = 'awaiting_payment',
  payment_failed = 'payment_failed',
  submitted = 'submitted',
  validation_failed = 'validation_failed',
  verified = 'verified',
  in_production = 'in_production',
  on_hold = 'on_hold',
  shipped = 'shipped',
  cancelled = 'cancelled',
}

export interface OrderFlyerDto {
  url?: string;
  name?: string;
  type: OrderFlyerType;
  size?: FlyerSize;
}

export enum OrderFlyerType {
  artist_card = 'artist_card',
  gift_card = 'gift_card',
  marketing_promo = 'marketing_promo',
  packing_slip = 'packing_slip',
}

export interface FlyerSize extends Size {
  units: MetricSizeUnits;
}

export enum MetricSizeUnits {
  inches = 'inches',
  mm = 'mm',
}

export enum RushType {
  sla_increase = 'sla_increase',
  super_rush = 'super_rush',
  holiday = 'holiday',
  customer_service = 'customer_service',
  reprint_factory_fault = 'reprint_factory_fault',
  reprint_client_fault = 'reprint_client_fault',
  storage = 'storage',
}

export enum RushValue {
  less_1_day = 'less_1_day',
  same_day = 'same_day',
  '1_day' = '1_day',
  '2_days' = '2_days',
  '3_days' = '3_days',
  '4_days' = '4_days',
  '5_days' = '5_days',
  '6_days' = '6_days',
  '7_days' = '7_days',
  '8_days' = '8_days',
  '9_days' = '9_days',
  '10_days' = '10_days',
  '11_days' = '11_days',
  '12_days' = '12_days',
  '13_days' = '13_days',
  '14_days' = '14_days',
  '15_days' = '15_days',
}

export enum OrderCancelReason {
  customer_cancel = 'customer_cancel',
  customer_order_change = 'customer_order_change',
  order_duplicate = 'order_duplicate',
  order_issue = 'order_issue',
  order_item_incorrect = 'order_item_incorrect',
  order_item_quality = 'order_item_quality',
  other_reason = 'other_reason',
  unknown_reason = 'unknown_reason',
  shipping_delay = 'shipping_delay',
}
