
export interface GoogleAddressValidationRequest {
  address: {
    revision: number;
    regionCode: string;
    languageCode?: string;
    postalCode?: string;
    sortingCode?: string;
    administrativeArea?: string;
    locality?: string;
    sublocality?: string;
    addressLines: string[];
    recipients?: string[];
    organization?: string;
  };
  previousResponseId?: string;
}

export interface GoogleAddressValidationResponse {
  result: GoogleAddressValidationResult;
  responseId: string;
}

export interface GoogleAddressValidationResult {
  verdict: GoogleAddressValidationVerdict;
  address: GoogleAddress;
  geocode: GoogleGeocode;
  metadata?: GoogleMetadata;
  uspsData?: GoogleUspsData;
}

export interface GoogleUspsData {
  standardizedAddress: GoogleStandardizedAddress;
  deliveryPointCode: string;
  deliveryPointCheckDigit: string;
  dpvConfirmation: string;
  dpvFootnote: string;
  dpvCmra: string;
  dpvVacant: string;
  dpvNoStat: string;
  carrierRoute: string;
  carrierRouteIndicator: string;
  postOfficeCity: string;
  postOfficeState: string;
  fipsCountyCode: string;
  county: string;
  elotNumber: string;
  elotFlag: string;
  addressRecordType: string;
  dpvNoStatReasonCode: number;
  dpvDrop: string;
  dpvThrowback: string;
  dpvNonDeliveryDays: string;
  dpvNoSecureLocation: string;
  dpvPbsa: string;
  dpvDoorNotAccessible: string;
  dpvEnhancedDeliveryCode: string;
}

export interface GoogleStandardizedAddress {
  firstAddressLine: string;
  cityStateZipAddressLine: string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeExtension: string;
}

export interface GoogleMetadata {
  business: boolean;
  poBox: boolean;
  residential: boolean;
}

export interface GoogleGeocode {
  location: GoogleLocation;
  plusCode: GooglePlusCode;
  bounds: GoogleBounds;
  placeId: string;
  placeTypes: string[];
}

export interface GoogleBounds {
  low: Location;
  high: Location;
}

export interface GooglePlusCode {
  globalCode: string;
}

export interface GoogleLocation {
  latitude: number;
  longitude: number;
}

export interface GoogleAddress {
  formattedAddress: string;
  postalAddress: GooglePostalAddress;
  addressComponents: GoogleAddressComponent[];
  missingComponentTypes: string[];
}

export type GoogleAddressConfirmationLevel = 'CONFIRMED' | 'UNCONFIRMED_BUT_PLAUSIBLE' | 'UNCONFIRMED_AND_SUSPICIOUS';

export interface GoogleAddressComponent {
  componentName: GoogleComponentName;
  componentType: string;
  confirmationLevel: GoogleAddressConfirmationLevel;
  inferred?: boolean;
  replaced?: boolean;
}

export interface GoogleComponentName {
  text: string;
  languageCode?: string;
}

export interface GooglePostalAddress {
  regionCode: string;
  languageCode: string;
  postalCode?: string;
  administrativeArea: string;
  locality?: string;
  addressLines: string[];
}

export interface GoogleAddressValidationVerdict {
  inputGranularity: string;
  validationGranularity: GoogleAddressValidationGranularity;
  geocodeGranularity: string;
  addressComplete?: boolean;
  hasInferredComponents?: boolean;
  hasReplacedComponents?: boolean;
  hasUnconfirmedComponents?: boolean;
}

export enum GoogleAddressValidationGranularity {
  GRANULARITY_UNSPECIFIED = 'GRANULARITY_UNSPECIFIED', // Default value. This value is unused.
  SUB_PREMISE = 'SUB_PREMISE', // Below-building level result, such as an apartment.
  PREMISE = 'PREMISE', // Building-level result
  PREMISE_PROXIMITY = 'PREMISE_PROXIMITY', // A geocode that approximates the building-level location of the address.
  BLOCK = 'BLOCK', // The address or geocode indicates a block. Only used in regions which have block-level addressing, such as Japan
  ROUTE = 'ROUTE', // The geocode or address is granular to route, such as a street, road, or highway
  OTHER = 'OTHER', // All other granularities, which are bucketed together since they are not deliverable
}
