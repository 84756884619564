@if (!hidden) {
<button *ngIf="!link && label"
        mat-raised-button
        [matTooltip]="tooltip ? tooltip : ''"
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [style.width]="width"
        class="button"
        [disabled]="disabled"
        (click)="click($event)">
  <mat-icon class="btn-icon"
            *ngIf="icon">{{icon}}</mat-icon>
  {{label}}
</button>
<button *ngIf="icon && secondary && !label && !link"
        mat-icon-button
        [matTooltip]="tooltip ? tooltip : ''"
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [class.destructive-btn]="destructive"
        [routerLink]="routerLink"
        class="icon-btn"
        [disabled]="disabled"
        (click)="click($event)">
  <mat-icon class="btn-icon"
            *ngIf="icon">{{icon}}</mat-icon>
</button>

<button *ngIf="icon && !secondary && !label && !link"
        mat-mini-fab
        [matTooltip]="tooltip ? tooltip : ''"
        [color]="destructive ? 'warn': !secondary ? color : undefined"
        [class.destructive-btn]="destructive"
        [routerLink]="routerLink"
        class="icon-btn"
        [disabled]="disabled"
        (click)="click($event)">
  <mat-icon class="btn-icon"
            *ngIf="icon">{{icon}}</mat-icon>
</button>

<a *ngIf="!icon && link && !routerLink"
   mat-raised-button
   [matTooltip]="tooltip ? tooltip : ''"
   class="button"
   [href]="href"
   [target]="target">{{label}}</a>

<a *ngIf="icon && link && !routerLink"
   mat-icon-button
   [matTooltip]="tooltip ? tooltip : ''"
   class="button"
   [href]="href"
   [target]="target">
  <mat-icon>{{icon}}</mat-icon>
</a>

<a *ngIf="!icon && link && routerLink"
   mat-raised-button
   [matTooltip]="tooltip ? tooltip : ''"
   class="button"
   [routerLink]="routerLink">{{label}}</a>


<ng-template #confirmationDialogTpl>
  <h1 mat-dialog-title>{{ confirmationMessage }}</h1>
  <div mat-dialog-content>{{ confirmationDescription || '' }}</div>
  <div mat-dialog-actions
       class="dialog-actions">
    <button mat-flat-button
            mat-dialog-close>Cancel</button>
    <button mat-flat-button
            color="warn"
            [mat-dialog-close]="true"
            cdkFocusInitial>Confirm</button>
  </div>
</ng-template>
}
