import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_CONTEXT, ApiContext } from '@app/helper';
import { GoogleAddressValidationResponse, GooglePlace, GooglePlacesResponse } from '../model';
import { CountryCode } from '../../../components/common/model/shipping';

@Injectable({ providedIn: 'root' })
export class GoogleAddressService {
  static PLACES_API_URL = 'https://places.googleapis.com/v1/places';
  static ADDRESS_VALIDATION_API_URL = 'https://addressvalidation.googleapis.com/v1';

  private http$ = inject(HttpClient);

  public findAddress(textQuery: string, region = CountryCode.US): Observable<GooglePlace[]> {
    return this.http$
      .post<GooglePlacesResponse>(`${GoogleAddressService.PLACES_API_URL}:searchText`, {
        textQuery,
        regionCode: region ?? CountryCode.US,
        languageCode: 'en',
      }, {
        params: new HttpParams()
          .append('fields', 'places.displayName,places.formattedAddress,places.addressComponents')
          .append('regionCode', region ?? CountryCode.US)
          .append('languageCode', 'en'),
        context: new HttpContext().set(API_CONTEXT, ApiContext.google),
      })
      .pipe(map(r => r?.places || []));
  }

  public validateAddress(address: string, regionCode: CountryCode, previousResponseId?: string): Observable<GoogleAddressValidationResponse> {
    return this.http$.post<GoogleAddressValidationResponse>(`${GoogleAddressService.ADDRESS_VALIDATION_API_URL}:validateAddress`, {
      address: {
        revision: 0,
        regionCode: regionCode ?? CountryCode.US,
        addressLines: [address],
      },
      ...(previousResponseId && { previousResponseId })
    }, {
      context: new HttpContext().set(API_CONTEXT, ApiContext.google)
    });
  }
}
