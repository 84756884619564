import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, reduce } from 'rxjs/operators';
import { BulkOrderSubmissionFailed, BulkOrderSubmissionResult, Order, OrderBase, OrderCancelReason, OrderFilter, OrderSubmit } from '@app/components/common/model/order';
import { addPageParams, Page } from '@common/model/page';
import { ArrayUtil } from '@app/helper';
import { NotificationHandler } from '@app/common/helper';
import { ErrorUtil } from '@app/common/helper/error.util';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private static readonly ORDER_ID = 'ORDER_ID';
  private static readonly ITEM_ID = 'ITEM_ID';

  static readonly BASE_URL = '/orders';
  private static readonly BULK_ORDERS = '/bulk/orders';
  private static readonly ORDER_DETAILS_URL = `${OrderService.BASE_URL}/${OrderService.ORDER_ID}`;
  private static readonly CANCEL_ORDER_URL = `${OrderService.ORDER_DETAILS_URL}/cancel`;
  private static readonly HOLD_ORDER_URL = `${OrderService.ORDER_DETAILS_URL}/hold`;
  private static readonly CHECK_ORDER_PAYMENT_URL = `${OrderService.ORDER_DETAILS_URL}/check-payment`;
  private static readonly DELETE_ITEM_URL = `${OrderService.ORDER_DETAILS_URL}/items/${OrderService.ITEM_ID}`;

  constructor(private http: HttpClient) {
  }

  public submitOrder(order: OrderSubmit): Observable<Order> {
    return this.http.post<Order>(`${environment.api.integration}${OrderService.BASE_URL}`, order);
  }

  public submitOrders(orders: OrderSubmit[]): Observable<BulkOrderSubmissionResult> {
    if (!orders.length) {
      NotificationHandler.handleError('There are no orders to import');
      return of({ orders: [] });
    }

    /* Split orders into batches of {chunkSize} to work around the API limit on the maximum number of orders allowed for bulk submission. */
    const chunkSize = 20;
    return of(orders).pipe(
      concatMap(orders => from(ArrayUtil.arrayChunk(orders, chunkSize)).pipe(
        mergeMap(batch => this.http.post<BulkOrderSubmissionResult>(
          `${environment.api.integration}${OrderService.BULK_ORDERS}`,
          batch
        ).pipe(
          catchError((err) => of({
            orders: batch.map(() => ({
              success: false,
              message: ErrorUtil.extractErrorMessage(err),
            } as BulkOrderSubmissionFailed))
          })),
        )),
        reduce((acc: BulkOrderSubmissionResult, batch) =>
          ({ orders: [...acc.orders, ...batch.orders] }),
          { orders: [] } as BulkOrderSubmissionResult
        ),
      ))
    )
  }

  public getOrders(page: Page<unknown>, orderFilter?: OrderFilter): Observable<Page<OrderBase>> {
    const url = addPageParams(OrderService.BASE_URL, { ...page, page: page.page + 1 });

    let params = new HttpParams();
    if (orderFilter?.status) {
      params = params.set('status', orderFilter.status);
    }

    if (orderFilter?.clientOrderId) {
      params = params.set('clientOrderId', orderFilter.clientOrderId);
    }

    if (orderFilter?.search) {
      params = params.set('search', orderFilter.search);
    }

    return this.http.get<Page<OrderBase>>(`${environment.api.integration}${url}`, { params })
      .pipe(
        map(data => {
          data.page -= 1;

          return data;
        })
      );
  }

  public getOrderDetails(orderId: string): Observable<Order> {
    const url = OrderService.ORDER_DETAILS_URL.replace(OrderService.ORDER_ID, orderId);

    return this.http.get<Order>(`${environment.api.integration}${url}`);
  }

  public cancelOrder(orderId: string, reason: OrderCancelReason): Observable<OrderBase> {
    const url = OrderService.CANCEL_ORDER_URL.replace(OrderService.ORDER_ID, orderId);

    return this.http.post<Order>(`${environment.api.integration}${url}`, {
      reason
    });
  }

  public holdOrder(orderId: string): Observable<OrderBase> {
    const url = OrderService.HOLD_ORDER_URL.replace(OrderService.ORDER_ID, orderId);

    return this.http.post<OrderBase>(`${environment.api.integration}${url}`, {});
  }

  public releaseOrder(orderId: string): Observable<OrderBase> {
    const url = OrderService.HOLD_ORDER_URL.replace(OrderService.ORDER_ID, orderId);

    return this.http.delete<OrderBase>(`${environment.api.integration}${url}`, { body: {} });
  }

  public checkOrderPayment(orderId: string): Observable<Order> {
    const url = OrderService.CHECK_ORDER_PAYMENT_URL.replace(OrderService.ORDER_ID, orderId);

    return this.http.post<Order>(`${environment.api.integration}${url}`, {});
  }

  public deleteOrderItem(orderId: string, itemId: string, reason: { reason: string; }): Observable<void> {
    const url = OrderService.DELETE_ITEM_URL
      .replace(OrderService.ORDER_ID, orderId)
      .replace(OrderService.ITEM_ID, itemId);

    return this.http.delete<void>(`${environment.api.integration}${url}`, { body: reason });
  }
}
