import { ProductMappingField } from '@entities/product';
import { ImageBleedMethod } from './bleed-method.model';
import { Option } from './option.model';
import { Size } from './size.model';

export enum ActionType {
  product_mapping = 'product_mapping',
  product_item_image_cutting = 'product_item_image_cutting',
}

export type OrderItemMetadata = {
  [key in ProductMappingField | string]?: string;
};

export interface OrderItem {
  id: string;
  clientItemId?: string;
  quantity: number;
  productCode: string;
  productSizes?: Size[];
  clientProductCode: string;
  name?: string;
  designImages: string[];
  imageBackLabel?: string;
  bleedMethod?: ImageBleedMethod;
  productPage?: string;
  productPreview?: string;
  pinColor?: string;
  hsCode?: number;
  readyToPrint?: boolean;
  options?: Option[];

  requiredActionType?: ActionType;
  metadata?: OrderItemMetadata;
}
