export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export interface ShippingAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface ShippingInfo extends ShippingAddress {
  fullName: string;
  email?: string;
  phone?: string;
  notes?: string;
  residential?: boolean;
}

export enum ShippingCarrier {
  fedex = 'fedex',
  ups = 'ups',
  usps = 'usps',
}

export enum ShippingCode {
  standard_ground = 'standard-ground',
  express = 'express',
  fedex_2day = 'fedex_2day',
  fedex_2day_am = 'fedex_2day_am',
  fedex_3_day_freight = 'fedex_3_day_freight',
  fedex_first_overnight = 'fedex_first_overnight',
  fedex_ground = 'fedex_ground',
  fedex_ground_international = 'fedex_ground_international',
  fedex_home_delivery = 'fedex_home_delivery',
  fedex_international_economy = 'fedex_international_economy',
  fedex_international_first = 'fedex_international_first',
  fedex_international_priority = 'fedex_international_priority',
  fedex_international_priority_express = 'fedex_international_priority_express',
  fedex_priority_overnight = 'fedex_priority_overnight',
  fedex_smartpost_parcel_select = 'fedex_smartpost_parcel_select',
  fedex_standard_overnight = 'fedex_standard_overnight',
  ups_2nd_day_air_am = 'ups_2nd_day_air_am',
  ups_ground = 'ups_ground',
  ups_ground_international = 'ups_ground_international',
  ups_next_day_air = 'ups_next_day_air',
  ups_next_day_air_early_am = 'ups_next_day_air_early_am',
  ups_next_day_air_international = 'ups_next_day_air_international',
  ups_next_day_air_saver = 'ups_next_day_air_saver',
  ups_standard_international = 'ups_standard_international',
  ups_worldwide_expedited = 'ups_worldwide_expedited',
  ups_worldwide_express = 'ups_worldwide_express',
  ups_worldwide_express_plus = 'ups_worldwide_express_plus',
  ups_worldwide_saver = 'ups_worldwide_saver',
  usps_first_class_mail = 'usps_first_class_mail',
  usps_first_class_mail_international = 'usps_first_class_mail_international',
  usps_parcel_select = 'usps_parcel_select',
  usps_priority_mail = 'usps_priority_mail',
  usps_priority_mail_express = 'usps_priority_mail_express',
  usps_priority_mail_express_international = 'usps_priority_mail_express_international',
  usps_priority_mail_international = 'usps_priority_mail_international',
}

export interface AddressValidationResult {
  action: 'FIX' | 'CONFIRM' | 'ACCEPT';
  enteredAddress: ShippingAddress;
  formattedEnteredAddress: string;
  suggestedAddress: ShippingAddress;
  formattedSuggestedAddress: string;
  residential: boolean;
  issues: {
    ['missing']: string[];
  }
}
