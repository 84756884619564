import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injectable } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { InjectorInstance } from './inject.helper';
import { ErrorDetails, ErrorUtil } from './error.util';

interface ErrorInfo {
  error?: {
    message?: string;
  }
  message?: string;
  statusText?: string;
  code?: string;
}

@Injectable()
export class NotificationHandler {

  static handleError(err?: string | ErrorInfo, details?: string | ErrorDetails | HttpErrorResponse | Error): void {
    let errorDetails: string | undefined;
    if (details) {
      errorDetails = ErrorUtil.extractErrorMessage(details);
    }

    const error: string = err
      ? typeof err === 'string'
        ? err
        : (err.error ? err.error.message : null) || err.message || err.statusText || err.code || 'Unknown error'
      : errorDetails ? errorDetails : 'Unknown error';

    console.error(err);
    if (details) {
      console.error(details);
    }

    InjectorInstance.get<MatSnackBar>(MatSnackBar).openFromComponent(ErrorDetailsComponent, {
      duration: 10000,
      panelClass: 'error',
      data: {
        error,
        details: errorDetails
      }
    });
  }

  static notification(message: string): void {
    InjectorInstance.get<MatSnackBar>(MatSnackBar).openFromComponent(NotificationDetailsComponent, {
      duration: 10000,
      data: {
        details: message
      }
    });
  }
}

interface DetailsData {
  error: string;
  details: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'snack-bar-notification-details',
  template: `
    <div class="details">{{details}}</div>
  `,
  styles: [`
    .details {
      color: white;
    }
  `],
})
export class NotificationDetailsComponent {

  details: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DetailsData) {
    this.details = data.details;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'snack-bar-error-details',
  template: `
    <div>{{error}}</div>
    <div *ngIf="details" class="small-text details">{{details}}</div>`,
  styles: [`
    .small-text {
      font-size: 75%;
    }

    .details {
      color: white;
    }
  `],
})
export class ErrorDetailsComponent {

  error: string;
  details: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DetailsData) {
    this.error = data.error;
    this.details = data.details;
  }
}
