import { Injectable, inject } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddressValidationResult, CountryCode, ShippingAddress } from '../model/shipping';
import { GoogleAddressService, GoogleAddressValidationGranularity, GoogleAddressValidationResponse } from '@app/api/google';
import { RVPIntegrationService, ShippingRate, ShippingRateQuery } from '@app/api/integration';

export interface CountryState {
  abbr: string;
  name: string;
}

export interface AddressValidationInput {
  addressLines: string[];
  regionCode: CountryCode;
  state: string;
  postalCode?: string;
  responseId?: string;
}

// ShippingService is not provided at the root level
// It should be registered at component level
@Injectable()
export class ShippingService {
  private addressService = inject(GoogleAddressService);
  private integrationservice = inject(RVPIntegrationService);
  private readonly addressValidationResponseId$ = new BehaviorSubject<string | undefined>(undefined);

  private static US_STATES: CountryState[] = [
    {
      'name': 'Alabama',
      'abbr': 'AL'
    },
    {
      'name': 'Alaska',
      'abbr': 'AK'
    },
    {
      'name': 'Arizona',
      'abbr': 'AZ'
    },
    {
      'name': 'Arkansas',
      'abbr': 'AR'
    },
    {
      'name': 'California',
      'abbr': 'CA'
    },
    {
      'name': 'Colorado',
      'abbr': 'CO'
    },
    {
      'name': 'Connecticut',
      'abbr': 'CT'
    },
    {
      'name': 'Delaware',
      'abbr': 'DE'
    },
    {
      'name': 'Florida',
      'abbr': 'FL'
    },
    {
      'name': 'Georgia',
      'abbr': 'GA'
    },
    {
      'name': 'Hawaii',
      'abbr': 'HI'
    },
    {
      'name': 'Idaho',
      'abbr': 'ID'
    },
    {
      'name': 'Illinois',
      'abbr': 'IL'
    },
    {
      'name': 'Indiana',
      'abbr': 'IN'
    },
    {
      'name': 'Iowa',
      'abbr': 'IA'
    },
    {
      'name': 'Kansas',
      'abbr': 'KS'
    },
    {
      'name': 'Kentucky',
      'abbr': 'KY'
    },
    {
      'name': 'Louisiana',
      'abbr': 'LA'
    },
    {
      'name': 'Maine',
      'abbr': 'ME'
    },
    {
      'name': 'Maryland',
      'abbr': 'MD'
    },
    {
      'name': 'Massachusetts',
      'abbr': 'MA'
    },
    {
      'name': 'Michigan',
      'abbr': 'MI'
    },
    {
      'name': 'Minnesota',
      'abbr': 'MN'
    },
    {
      'name': 'Mississippi',
      'abbr': 'MS'
    },
    {
      'name': 'Missouri',
      'abbr': 'MO'
    },
    {
      'name': 'Montana',
      'abbr': 'MT'
    },
    {
      'name': 'Nebraska',
      'abbr': 'NE'
    },
    {
      'name': 'Nevada',
      'abbr': 'NV'
    },
    {
      'name': 'New Hampshire',
      'abbr': 'NH'
    },
    {
      'name': 'New Jersey',
      'abbr': 'NJ'
    },
    {
      'name': 'New Mexico',
      'abbr': 'NM'
    },
    {
      'name': 'New York',
      'abbr': 'NY'
    },
    {
      'name': 'North Carolina',
      'abbr': 'NC'
    },
    {
      'name': 'North Dakota',
      'abbr': 'ND'
    },
    {
      'name': 'Ohio',
      'abbr': 'OH'
    },
    {
      'name': 'Oklahoma',
      'abbr': 'OK'
    },
    {
      'name': 'Oregon',
      'abbr': 'OR'
    },
    {
      'name': 'Pennsylvania',
      'abbr': 'PA'
    },
    {
      'name': 'Rhode Island',
      'abbr': 'RI'
    },
    {
      'name': 'South Carolina',
      'abbr': 'SC'
    },
    {
      'name': 'South Dakota',
      'abbr': 'SD'
    },
    {
      'name': 'Tennessee',
      'abbr': 'TN'
    },
    {
      'name': 'Texas',
      'abbr': 'TX'
    },
    {
      'name': 'Utah',
      'abbr': 'UT'
    },
    {
      'name': 'Vermont',
      'abbr': 'VT'
    },
    {
      'name': 'Virginia',
      'abbr': 'VA'
    },
    {
      'name': 'Washington',
      'abbr': 'WA'
    },
    {
      'name': 'West Virginia',
      'abbr': 'WV'
    },
    {
      'name': 'Wisconsin',
      'abbr': 'WI'
    },
    {
      'name': 'Wyoming',
      'abbr': 'WY'
    },
    {
      'name': 'District of Columbia',
      'abbr': 'DC'
    },
    {
      'name': 'American Samoa',
      'abbr': 'AS'
    },
    {
      'name': 'Guam',
      'abbr': 'GU'
    },
    {
      'name': 'Northern Mariana Islands',
      'abbr': 'MP'
    },
    {
      'name': 'Puerto Rico',
      'abbr': 'PR'
    },
    {
      'name': 'United States Minor Outlying Islands',
      'abbr': 'UM'
    },
    {
      'name': 'Virgin Islands, U.S.',
      'abbr': 'VI'
    }
  ];

  private static CANADA_STATES: CountryState[] = [
    {
      'name': 'Ontario',
      'abbr': 'ON'
    },
    {
      'name': 'Quebec',
      'abbr': 'QC'
    },
    {
      'name': 'Nova Scotia',
      'abbr': 'NS'
    },
    {
      'name': 'New Brunswick',
      'abbr': 'NB'
    },
    {
      'name': 'Manitoba',
      'abbr': 'MB'
    },
    {
      'name': 'British Columbia',
      'abbr': 'BC'
    },
    {
      'name': 'Prince Edward Island',
      'abbr': 'PE'
    },
    {
      'name': 'Saskatchewan',
      'abbr': 'SK'
    },
    {
      'name': 'Alberta',
      'abbr': 'AB'
    },
    {
      'name': 'Newfoundland and Labrador',
      'abbr': 'NL'
    },
    {
      'name': 'Northwest Territories',
      'abbr': 'NT'
    },
    {
      'name': 'Nunavut',
      'abbr': 'NU'
    },
    {
      'name': 'Yukon',
      'abbr': 'YT'
    },
  ];

  public getCountryCodes(): CountryCode[] {
    return Object.keys(CountryCode) as CountryCode[];
  }

  public getStates(code: CountryCode): CountryState[] {
    if (code === CountryCode.US) {
      return ShippingService.US_STATES;
    }

    if (code === CountryCode.CA) {
      return ShippingService.CANADA_STATES;
    }

    return [];
  }

  public findAddreses(input: string, region = CountryCode.US): Observable<{ formattedAddress: string; address: ShippingAddress; }[]> {
    return this.addressService.findAddress(input, region).pipe(
      map(places => places.map(place => {
        const streetNumber = place.addressComponents.find(cmp => cmp.types?.includes('street_number'))?.longText || '';
        const route = place.addressComponents.find(cmp => cmp.types?.includes('route'))?.longText || '';

        const address: ShippingAddress = {
          address1: `${streetNumber} ${route}`,
          address2:
            place.addressComponents.find(cmp => cmp.types?.includes('subpremise'))?.longText || '',
          state:
            place.addressComponents.find(cmp => cmp.types?.includes('administrative_area_level_1'))?.shortText || '',
          zip:
            place.addressComponents.find(cmp => cmp.types?.includes('postal_code'))?.longText || '',
          city:
            place.addressComponents.find(cmp => cmp.types?.includes('locality'))?.longText || '',
          country:
            place.addressComponents.find(cmp => cmp.types?.includes('country'))?.shortText || '',
        };

        return {
          formattedAddress: place.formattedAddress || place.displayName.text,
          address,
        };
      }))
    );
  }

  public validateAddress(address: ShippingAddress): Observable<AddressValidationResult> {
    return this.addressService.validateAddress(
      this.transformShippingAddressToString(address),
      address.country as CountryCode || CountryCode.US,
      this.addressValidationResponseId$.getValue(),
    ).pipe(
      map(response => {
        if (!this.addressValidationResponseId$.value) {
          // This field must be empty for the first address validation request.
          // If more requests are necessary to fully validate a single address
          // (for example if the changes the user makes after the initial validation need to be re-validated),
          // then each followup request must populate this field with the responseId from the very first response in the validation sequence.
          // https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress
          this.addressValidationResponseId$.next(response.responseId);
        }
        return this.parseGoogleValidationResult(address, response);
      }),
    );
  }

  public getShippingRate(data: ShippingRateQuery): Observable<ShippingRate> {
    return this.integrationservice.getShippingRate(data);
  }

  private parseGoogleValidationResult(input: ShippingAddress, response: GoogleAddressValidationResponse): AddressValidationResult {
    const { verdict, address, metadata } = response.result;
    const issues: { ['missing']: string[] } = { missing: [] };

    if (address.missingComponentTypes?.length) {
      issues.missing = address.missingComponentTypes;
    }

    const result = {
      issues,
      enteredAddress: input,
      formattedEnteredAddress: this.transformShippingAddressToString(input),
      formattedSuggestedAddress: address.formattedAddress,
      suggestedAddress: {
        address1: address.postalAddress.addressLines.join(', '),
        city: address.postalAddress.locality || '',
        state: address.postalAddress.administrativeArea,
        country: address.postalAddress.regionCode,
        zip: address.postalAddress.postalCode || '',
      },
      residential: Boolean(metadata?.residential),
    };

    // address is valid
    if (
      verdict.addressComplete &&
      !verdict.hasReplacedComponents &&
      !verdict.hasInferredComponents &&
      !verdict.hasUnconfirmedComponents &&
      (
        verdict.validationGranularity === GoogleAddressValidationGranularity.SUB_PREMISE ||
        verdict.validationGranularity === GoogleAddressValidationGranularity.PREMISE
      )
    ) {
      return {
        ...result,
        action: 'ACCEPT',
      };
    }

    // address is valid, but confirmation required
    if (
      verdict.addressComplete &&
      (
        verdict.validationGranularity === GoogleAddressValidationGranularity.SUB_PREMISE ||
        verdict.validationGranularity === GoogleAddressValidationGranularity.PREMISE ||
        verdict.validationGranularity === GoogleAddressValidationGranularity.PREMISE_PROXIMITY ||
        verdict.validationGranularity === GoogleAddressValidationGranularity.BLOCK ||
        verdict.validationGranularity === GoogleAddressValidationGranularity.ROUTE
      ) &&
      (
        verdict.hasInferredComponents || verdict.hasReplacedComponents || verdict.hasUnconfirmedComponents
      )
    ) {
      return {
        ...result,
        action: 'CONFIRM',
      };
    }

    // address is invalid, edit required
    return {
      ...result,
      action: 'FIX',
    };
  }

  private transformShippingAddressToString(address: ShippingAddress): string {
    return [address.address1, address.address2, address.city, address.state, address.zip, address.country]
      .filter(Boolean)
      .join(', ')
  }
}
