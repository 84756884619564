import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import {
  CreateProductMapping, ProductMapping, QueryProductMapping, UpdateProductMapping,
} from '../model/product-mapping.model';
import { ProductService } from './product.service';

@Injectable({ providedIn: 'root' })
export class ProductMappingService {
  private readonly http = inject(HttpClient);

  static readonly endpoint = `${environment.api.integration}${ProductService.BASE_URL}/mappings`;

  getMappings(query?: QueryProductMapping): Observable<ProductMapping[]> {
    let params = new HttpParams();

    if (query?.codes?.length) {
      params = params.set('codes', query?.codes.join(','))
    }

    return this.http.get<ProductMapping[]>(ProductMappingService.endpoint, { params });
  }

  createMapping(data: CreateProductMapping): Observable<CreateProductMapping> {
    return this.http.post<CreateProductMapping>(ProductMappingService.endpoint, this.prepareMapping(data));
  }

  updateMapping(mappingId: string, data: UpdateProductMapping): Observable<CreateProductMapping> {
    return this.http.put<CreateProductMapping>(`${ProductMappingService.endpoint}/${mappingId}`, this.prepareMapping(data));
  }

  deleteMapping(mappingId: string): Observable<void> {
    return this.http.delete<void>(`${ProductMappingService.endpoint}/${mappingId}`);
  }

  private prepareMapping(data: CreateProductMapping): CreateProductMapping {
    return {
      ...data,
      rules: data.rules.map(r => ({ ...r, value: r.value?.trim() })),
    };
  }
}
