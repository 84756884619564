export enum TransformationType {
  add_bleed = 'add_bleed',  // add image bleed with selected method
  add_custom_mat = 'add_custom_mat', // add custom mat to the image
}

export type ProductType = string;

export enum ProductTypeFormat {
  framed = 'framed',
  standard = 'standard'
}

export interface ProductTypeDetails {
  type: ProductType;
  format: ProductTypeFormat;
  name: string;
  description?: string;
  preview?: string;
  transformations?: TransformationType[];
}
