import { ActionType, ImageBleedMethod } from '@entities/order';
import { FrameType } from '@entities/product';

export enum ProductMappingField {
  item_name = 'item_name',
  item_sku = 'item_sku',
  item_fulfillment_sku = 'item_fulfillment_sku',
  order_id = 'order_id',
  custom_image = 'custom_image',
}

export enum ProductMappingOperator {
  equals = 'equals',
}

export interface ProductMappingRule {
  field: ProductMappingField,
  value: string,
  operator: ProductMappingOperator
}

export interface ProductMappingProps {
  frameType: FrameType;
  frameColor: string;
  matSize: string;
  matColor?: string;
  bleedMethod: ImageBleedMethod;
  bleedColor: string;
  readyToPrint: boolean;
}

export interface ProductMapping extends Partial<ProductMappingProps> {
  id: string;
  actionType: ActionType;
  rules: ProductMappingRule[];

  productCode: string;
  images: string[];
}

export type CreateProductMapping = Omit<ProductMapping, 'id'>;
export type UpdateProductMapping = CreateProductMapping;
export type QueryProductMapping = Partial<{ codes: string[]; }>;
