import { ImageBleedMethod } from '@entities/order';
import { ProductType, ProductTypeDetails, TransformationType } from './product-type.model';

export const EmptyOption: Pick<ProductOption, 'option' | 'price'> = { option: 'None', price: 0 };
export const MAT_SIZE_VALUES = Object.freeze([1, 1.5, 2, 2.5]);
export const DEFAULT_MAT_COLOR = '#FFFFFF';

export enum OptionType {
  cover = 'cover',
  finish = 'finish',
  frame = 'frame',
  hanging = 'hanging',
  other = 'other',
  print = 'print',
}

export enum FramingType {
  classicFlat = 'classic-flat',
  flatMount = 'flat-mount',
  floatMount = 'float-mount',
  metal = 'metal',
  modernDeep = 'modern-deep',
  modernThin = 'modern-thin',
  shadowBox = 'shadow-box',
  traditional = 'traditional',
}

export enum PrintOptionType {
  matColor = 'mat-color',
  matSize = 'mat-size',
  bleedColor = 'bleed-color',
}

export enum VirtualPrintOptionType {
  bleedMethod = 'bleed-method',
}


export enum PrintFinishOptionType {
  glitter = 'glitter',
}

export enum OtherOptionType {
  cover = 'cover', // type: cover
  hanging = 'hanging', // type: hanging
  other = 'other', // type: other
}

export type OptionSubType = FramingType | PrintFinishOptionType | PrintOptionType | VirtualPrintOptionType | OtherOptionType;

export interface ProductOptions {
  productType: ProductType
  [OptionType.frame]?: ProductOptionsGroup[];
  [OptionType.finish]?: ProductOptionsGroup[];
  [OptionType.hanging]?: ProductOptionsGroup[];
  [OptionType.cover]?: ProductOptionsGroup[];
  [OptionType.other]?: ProductOptionsGroup[];
  [OptionType.print]?: ProductOptionsGroup[];
}

export interface ProductOptionsGroup {
  required?: boolean;
  type?: OptionType;
  subType: OptionSubType;
  name: string;
  description?: string;
  options: ProductOption[];
}

export interface ProductOption {
  name?: string; // TODO: FA-1352: add the options group entity with posibility to set name and optional description
  productType: ProductType;
  option: string;
  price: number;
  subType: OptionSubType;
  type: OptionType;
  preview?: string;
}

export interface SelectedOption {
  type: OptionType;
  subType?: OptionSubType;
  option: Pick<ProductOption, 'option' | 'price'>;
}

export interface ProductOptionsData {
  name?: string;
  description?: string;

  options: ProductOption[];
}

export const createMatSizeOptionsGroup = (productType: string) => Object.freeze({
  name: 'Mat Size',
  type: OptionType.print,
  subType: PrintOptionType.matSize,
  options: MAT_SIZE_VALUES.map(size => ({
    productType,
    option: String(size),
    price: 0,
    subType: PrintOptionType.matSize,
    type: OptionType.print,
  })),
});

export const createMatColorOptionsGroup = (productType: string) => Object.freeze({
  name: 'Mat Color',
  type: OptionType.print,
  subType: PrintOptionType.matColor,
  options: [{
    productType,
    option: 'color-code',
    price: 0,
    subType: PrintOptionType.matColor,
    type: OptionType.print,
  }],
});

export const createBleedMethodOptionsGroup = (productType: string) => Object.freeze({
  name: 'Bleed Method',
  type: OptionType.print,
  subType: VirtualPrintOptionType.bleedMethod,
  options: Object.values(ImageBleedMethod).map(method => ({
    productType,
    option: String(method),
    price: 0,
    subType: VirtualPrintOptionType.bleedMethod as OptionSubType,
    type: OptionType.print,
  })),
});

export const createBleedColorOptionsGroup = (productType: string) => Object.freeze({
  name: 'Bleed Color',
  type: OptionType.print,
  subType: PrintOptionType.bleedColor,
  options: [{
    productType,
    option: 'color-code',
    price: 0,
    subType: PrintOptionType.bleedColor,
    type: OptionType.print,
  }],
});

// PrintOptionType
export const createPrintOptions = (productType: ProductTypeDetails) => {
  const opts: ProductOptionsGroup[] = [];

  if (productType.transformations?.includes(TransformationType.add_custom_mat)) {
    opts.push(createMatSizeOptionsGroup(productType.type));
    opts.push(createMatColorOptionsGroup(productType.type));
  }

  if (productType.transformations?.includes(TransformationType.add_bleed)) {
    opts.push(createBleedMethodOptionsGroup(productType.type));
    opts.push(createBleedColorOptionsGroup(productType.type));
  }

  return opts;
};
